window.jQuery = window.$ = require('../libs/jquery/jquery.js') //AMD加载模式下，手动执行这句话。+

window.OpenLayers = require('../libs/OpenLayers/OpenLayers.js')

require('../libs/jquery/jquery-ui.js')
require('../libs/jquery/jquery-ui.css')

require('../libs/jquery/jquery-ui-timepicker-addon.js') //
require('../libs/bootstrap-datepicker/bootstrap-datetimepicker.js')
require('../libs/bootstrap-datepicker/bootstrap-datepicker.css')

require('../libs/bootstrap/bootstrap.js')
require('../libs/bootstrap/bootstrap.css')

require('../libs/jsgrid/jsgrid.js')
require('../libs/jsgrid/jsgrid.min.css')

window.toastr = require('../libs/bootstrap-toastr/toastr.min.js')
require('../libs/bootstrap-toastr/toastr.min.css')

require('../js/ripples.js')
require('../css/ripples.css')

// require('../js/material.js')
require('../css/balloon.min.css')
require('../fonts/iconfont.css')
require('../css/font-awesome.css')
require('../fonts/icon-font/iconfont.css')

// require('../js/alog')
// require('../js/alog.err')
// require('../js/catch-exceptions.js')